import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Grid, TextField } from '@mui/material';
import APIService from '../../../../APIService';
import swal from 'sweetalert';
import Form from 'react-bootstrap/Form';

export default function Shedule() {

  const [schedules, setSchedules] = useState([])
  const [title, setTitle] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [fileUpload, setFileUpload] = useState("");
  const [scheduleId, setScheduleId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    APIService.get('schedule').then((response) => {
      setSchedules(response.data);
    })
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFileUpload(e.target.files[0]);
    }
  };

  const changePosition = (direction, itemId) => {

    APIService.post([], `change-position/${direction}/schedule/${itemId}`)
      .then((response) => {
        // console.log(response);
        if (response.success) {
          APIService.get('schedule').then((response) => {
            setSchedules(response.data);
          });
        }
      })
  }

  const handleEdit = (itemId, title, year, month) => {
    setScheduleId(itemId);
    setIsEditing(true);
    setTitle(title);
    setYear(year);
    setMonth(month)
  };


  const handleDelete = (itemId) => {
    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        APIService.delete(`schedule/${itemId}`).then((response) => {
          swal("Success", response.message, "success", {
            buttons: false,
            timer: 2000,
          }).then((value) => {
            window.location.href = "/schedule";
          });
        });
      }
    });
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    const formData = new FormData();

    formData.append("title", title);
    formData.append("year", year);
    formData.append("month", month);
    formData.append("file_upload", fileUpload);

    if (isEditing) {
      var response = await APIService.put(formData, `schedule/${scheduleId}`);
    } else {
      var response = await APIService.post(formData, "schedule");
    }

    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/schedule";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  }

  return (
    <div>
      <h2 className="organisation-head">Schedule of Hearing</h2>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          {/* <Grid item xs={12} sm={6} lg={4}>
          <p id="labels">Sr.No</p>
          <TextField
            name="sr_no"
            label="Sr.No"
            variant="outlined"
            fullWidth
          />
        </Grid> */}
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload File</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={handleFileChange}
            />
          </Grid>


          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Year</p>
            <TextField
              type="number"
              name="year"
              variant="outlined"
              fullWidth
              value={year}
              onChange={e => setYear(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6} lg={6} className='order-select-option'>

            <p id="labels">Select Month</p>
            <Form.Select
              aria-label="Default select example"
              value={month}
              onChange={e => setMonth(e.target.value)}
            >
              <option value="" disabled>Select</option>
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </Form.Select>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Title</p>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Title"
              multiline
              rows={3}
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type='submit'>Submit</Button>{' '}
      </form>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>Sl No</th>
            <th>Title</th>
            <th>Year</th>
            <th>Month</th>
            <th>Edit</th>
            <th>Delete</th>
            <th>Move Up</th>
            <th>Move Down</th>
          </tr>
        </thead>
        <tbody>
          {schedules.map((schedule, index) => (
            <tr key={schedule.id}>
              <td>{index + 1}</td>
              <td>{schedule.title}</td>
              <td>{schedule.year}</td>
              <td>{schedule.month}</td>

              <td><button className='tablebutton'
                onClick={() => handleEdit(schedule.id, schedule.title, schedule.year, schedule.month)}
              >Edit</button></td>
              <td><button className='tablebutton'
                onClick={() => handleDelete(schedule.id)}
              >Delete</button></td>
              <td><button className='move-btn'
                onClick={() => changePosition("up", schedule.id)}

              ><img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" /></button></td>
              <td><button className='move-btn'
                onClick={() => changePosition("down", schedule.id)}
              ><img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" /></button></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}