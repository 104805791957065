import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Grid, TextField } from '@mui/material';
import swal from 'sweetalert';
import { LoadingButton } from '@mui/lab';
import APIService from '../../../../APIService';

export default function Career() {

  const [careers, setCareers] = useState([]);
  const [date, setDate] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [fileUpload, setFileUpload] = useState("");

  const [careerId, setCareerId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    APIService.get('careers').then((response) => {
      setCareers(response.careers);
    })
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFileUpload(e.target.files[0]);
    }
  };

  const changePosition = (direction, itemId) => {

    APIService.post([], `change-position/${direction}/careers/${itemId}`)
      .then((response) => {
        // console.log(response);
        if (response.success) {
          APIService.get('careers').then((response) => {
            setCareers(response.careers);
          });
        }
      })
  }

  const handleEdit = (itemId, title, date, description) => {

    setCareerId(itemId);
    setIsEditing(true);
    setTitle(title);
    setDate(date);
    setDescription(description);
  }

  const handleDelete = (itemId) => {

    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          APIService.delete(`careers/${itemId}`)
            .then(response => {
              swal("Success", response.message, "success", {
                buttons: false,
                timer: 2000,
              }).then((value) => {
                window.location.href = "/careers";
              });
            });
        }
      });
  }

  const handleSubmit = async (e) => {

    e.preventDefault();

    const formData = new FormData();

    formData.append("job_title", title);
    formData.append("description", description);
    formData.append("last_date", date);
    formData.append("file_upload", fileUpload);

    if (isEditing) {
      var response = await APIService.put(formData, `careers/${careerId}`);
    } else {
      var response = await APIService.post(formData, 'careers');
    }

    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/careers";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  }

  return (
    <div>
      <h2>Careers</h2>
      <form className="login-form" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Title</p>
            <TextField
              name="title"
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Date</p>
            <TextField
              type='date'
              name="number"
              variant="outlined"
              fullWidth
              value={date}
              onChange={e => setDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload File</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={handleFileChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Description</p>
            <TextField
              fullWidth
              value={description}
              onChange={e => setDescription(e.target.value)}
              id="outlined-multiline-static"
              label="Name"
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
        <div className='login-btn' style={{ marginTop: 30 }}>
          <LoadingButton type="submit" variant="contained" id='login-btn'>
            Submit
          </LoadingButton>
        </div>
        {/* <Button style={{ marginTop: 20 }} variant="primary" onClick={handleUpload}>Submit</Button> */}
        {/* <Button style={{ marginTop: 20 }} variant="primary">Submit</Button>{' '} */}
      </form>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Last Date</th>
            <th>Edit</th>
            <th>Delete</th>
            <th>Move Up</th>
            <th>Move Down</th>
          </tr>
        </thead>
        <tbody>
          {careers.map(career => (
            <tr key={career.id}>
              <td>{career.job_title}</td>
              <td>{career.description}</td>
              <td>{career.last_date}</td>
              <td>
                <button
                  className='tablebutton'
                  onClick={() => handleEdit(career.id, career.job_title, career.last_date, career.description)}
                >
                  Edit
                </button>
              </td>
              <td>
                <button
                  className='tablebutton'
                  onClick={() => handleDelete(career.id)}
                >
                  Delete
                </button>
              </td>
              <td>
                <button
                  className='move-btn'
                  onClick={() => changePosition("up", career.id)}
                >
                  <img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" />
                </button>
              </td>
              <td>
                <button
                  className='move-btn'
                  onClick={() => changePosition("down", career.id)}
                >
                  <img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div >
  )
}
