import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Grid, TextField } from '@mui/material';
import APIService from '../../../../APIService';
import swal from 'sweetalert';

export const Quotations = () => {
  const [quotations, setQuotations] = useState([])
  const [title, setTitle] = useState("");
  const [addedOn, setAddedOn] = useState("");
  const [fileUpload, setFileUpload] = useState("");

  const [quotationId, setQuotationId] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    APIService.get('quotations').then((response) => {
      setQuotations(response.data);
    });
  }, []);
  const handleFileChange = (e) => {
    if (e.target.files) {
      setFileUpload(e.target.files[0]);
    }
  }

  const changePosition = (direction, itemId) => {

    APIService.post([], `change-position/${direction}/quotations/${itemId}`)
      .then((response) => {
        // console.log(response);
        if (response.success) {
          APIService.get('quotations').then((response) => {
            setQuotations(response.data);
          });
        }
      })
  }

  const handleEdit = (itemId, title, addedOn) => {
    setQuotationId(itemId);
    setIsEditing(true);
    setTitle(title)
    setAddedOn(addedOn)
  };


  const handleDelete = (itemId) => {
    // e.preventDefault();
    swal({
      title: "Are you sure?",
      text: "You want to delete this item?",
      icon: "warning",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        APIService.delete(`quotations/${itemId}`).then((response) => {
          swal("Success", response.message, "success", {
            buttons: false,
            timer: 2000,
          }).then((value) => {
            window.location.href = "/quotations";
          });
        });
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Format the date to yyyy-mm-dd format
    const formattedDate = addedOn ? new Date(addedOn).toISOString().split('T')[0] : ''; // Converts to yyyy-mm-dd format
  
    const formData = new FormData();
    formData.append("title", title);
    formData.append("added_on", formattedDate);
    formData.append("file_upload", fileUpload);
  
    if (isEditing) {
      var response = await APIService.put(formData, `quotations/${quotationId}`);
    } else {
      var response = await APIService.post(formData, "quotations");
    }
  
    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      }).then((value) => {
        window.location.href = "/quotations";
      });
    } else {
      swal("Failed", response.message, "error");
    }
  }
  
  
  return (
    <div>
      <h2 className="organisation-head">Quotations</h2>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          {/* <Grid item xs={12} sm={6} lg={4}>
          <p id="labels">SI.No</p>
          <TextField
            name="si_no"
            label="SI.No"
            variant="outlined"
            fullWidth
          />
        </Grid> */}
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Year</p>
            <TextField
              type="date"
              name="year"
             
              variant="outlined"
              fullWidth
              value={addedOn}
              onChange={e => setAddedOn(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <p id="labels">Upload File</p>
            <TextField
              name="upload-photo"
              type="file"
              onChange={handleFileChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={6} lg={12} md={8}>
            <p id="labels">Title</p>
            <TextField
              fullWidth
              id="outlined-multiline-static"
              label="Title"
              multiline
              rows={3}
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button style={{ marginTop: 20 }} variant="primary" type="submit">Submit</Button>{' '}
      </form>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>Sl No</th>
            <th>Title</th>
            <th>Year</th>
            <th>Edit</th>
            <th>Delete</th>
            <th>Move Up</th>
            <th>Move Down</th>
          </tr>
        </thead>
        <tbody>
          {quotations.map((quotation, index) => (
            <tr key={quotation.id}>
              <td>{index + 1}</td>
              <td>{quotation.title}</td>
              <td>{quotation.added_on}</td>
              <td><button className='tablebutton'
                onClick={() => handleEdit(quotation.id, quotation.title, quotation.added_on)}
               >Edit</button></td>
              <td><button className='tablebutton'
              onClick={() => handleDelete(quotation.id)}
              >Delete</button></td>
              <td><button className='move-btn'
               onClick={() => changePosition("up", quotation.id)}><img width="32" height="32" src="https://img.icons8.com/external-kmg-design-glyph-kmg-design/32/external-up-arrow-arrows-kmg-design-glyph-kmg-design.png" alt="external-up-arrow-arrows-kmg-design-glyph-kmg-design" /></button></td>
              <td><button className='move-btn'
              onClick={() => changePosition("down", quotation.id)}><img width="36" height="40" src="https://img.icons8.com/fluency-systems-filled/48/thick-arrow-pointing-down.png" alt="thick-arrow-pointing-down" /></button></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}